define("discourse/plugins/discourse-ifood-gamification-awards/discourse/components/coupon-awards-uploader", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _ajax, _ajaxError, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BULK_COUPON_INPUT_ID = "bulk-award-ifood-coupons";
  class CouponAwardsUploader extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "toasts", [_service.inject]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "uploading", [_tracking.tracked], function () {
      return false;
    }))();
    #uploading = (() => (dt7948.i(this, "uploading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "saving", [_tracking.tracked], function () {
      return false;
    }))();
    #saving = (() => (dt7948.i(this, "saving"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "fileSelected", [_tracking.tracked], function () {
      return false;
    }))();
    #fileSelected = (() => (dt7948.i(this, "fileSelected"), void 0))();
    get disabled() {
      return this.saving || !this.fileSelected;
    }
    async createCoupons() {
      try {
        this.saving = true;
        this.toasts.info({
          duration: 3000,
          data: {
            message: _discourseI18n.default.t("discourse_ifood_gamification_awards.admin.saving_coupons")
          }
        });
        const options = {
          type: "POST",
          processData: false,
          contentType: false,
          data: new FormData()
        };
        options.data.append("csv", this.fileSelected);
        await (0, _ajax.ajax)("/admin/plugins/gamification_awards.json", options);
        this.toasts.success({
          duration: 3000,
          data: {
            message: _discourseI18n.default.t("discourse_ifood_gamification_awards.admin.coupons_created")
          }
        });
        this.reset();
      } catch (error) {
        this.toasts.error({
          duration: 3000,
          data: {
            message: (0, _ajaxError.extractError)(error)
          }
        });
        this.reset();
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "createCoupons", [_object.action]))();
    updateFileSelected() {
      this.fileSelected = document.getElementById(BULK_COUPON_INPUT_ID)?.files?.[0] || false;
    }
    static #_6 = (() => dt7948.n(this.prototype, "updateFileSelected", [_object.action]))();
    reset() {
      this.saving = false;
      document.getElementById(BULK_COUPON_INPUT_ID).value = null;
      this.fileSelected = false;
    }
    static #_7 = (() => dt7948.n(this.prototype, "reset", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <br />
        <input
          type="file"
          id="bulk-award-ifood-coupons"
          accept=".csv"
          onchange={{this.updateFileSelected}}
        />
    
        <br /><br />
    
        <DButton
          @action={{this.createCoupons}}
          @disabled={{this.disabled}}
          @label="discourse_ifood_gamification_awards.admin.create_coupons"
          type="submit"
          class="btn btn-primary"
        />
    
        {{#if this.fileSelected}}
          <DButton @action={{this.reset}} @label="cancel" />
        {{/if}}
      
    */
    {
      "id": "3js24km2",
      "block": "[[[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[10,\"input\"],[14,1,\"bulk-award-ifood-coupons\"],[14,\"accept\",\".csv\"],[15,\"onchange\",[30,0,[\"updateFileSelected\"]]],[14,4,\"file\"],[12],[13],[1,\"\\n\\n    \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n\\n    \"],[8,[32,0],[[24,0,\"btn btn-primary\"],[24,4,\"submit\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"createCoupons\"]],[30,0,[\"disabled\"]],\"discourse_ifood_gamification_awards.admin.create_coupons\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"fileSelected\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@action\",\"@label\"],[[30,0,[\"reset\"]],\"cancel\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ifood-gamification-awards/discourse/components/coupon-awards-uploader.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CouponAwardsUploader;
});